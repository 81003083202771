import styled from "styled-components"
import Img from "gatsby-image"

import bg from "../../images/go-burguer-bg-burguer-do-mes.jpg"
export const ComponentWrapper = styled.div`
  position: fixed;
  bottom: -330px;
  width: 100%;
  background: white;
  font-family: "Arvo";
  background: url(${bg}) center/cover;
  z-index: 10;
  transition: all 0.5s;

  @media (max-width: 600px) {
    bottom: -445px;
  }

  &.active {
    bottom: 0;
  }
`

export const Header = styled.div`
  padding: 20px 10px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: #508987;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 14px;
    padding: 20px 5px;
  }
`

export const BurguerWrapper = styled.div`
  max-width: 980px;
  padding: 20px 10px;
  margin: 0 auto;
  background: #508987;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const Column = styled.div`
  padding: 0 10px;

  &:first-child {
    width: 45%;
  }

  &:last-child {
    width: 55%;
  }

  @media (max-width: 600px) {
    width: 100% !important;

    &:last-child {
      order: -1;
    }
  }
`

export const BurguerTitle = styled.h2`
  color: #ecd300;
  text-align: right;
  text-transform: uppercase;
  font-size: 20px;
  margin: 8px 0;

  @media (max-width: 600px) {
    margin: 6px 0;
    text-align: center;
    font-size: 18px;
  }
`

export const BurguerIngredients = styled.h4`
  color: #fff;
  text-align: right;
  line-height: 1.6;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 15px;
    line-height: 1.4;
  }
`

export const MonteImg = styled(Img)`
  width: 100%;
  max-width: 240px;
  margin-left: 80px;
  transform: rotate(-6deg) translate(-30px, -20px);
  @media (max-width: 600px) {
    max-width: 200px;
    margin: 0 auto;
    transform: rotate(-6deg) translate(0, -15px);
  }
`

export const Arrow = styled.span`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: none;
  border-bottom: 10px solid white;
  transform: translateY(-2px);
  display: inline-block;
  margin-right: 10px;

  &.active {
    border-top: 10px solid white;
    border-bottom: none;
  }
`
