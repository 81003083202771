import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

import Hero from "../components/Hero"
import Menu from "../components/Menu"
import About from "../components/About"
import Monte from "../components/Monte"
import Divider from "../components/Divider"

import img1 from "../images/go-burguer-jogo-basquete-bg.jpg"
import img2 from "../images/go-burguer-jogo-nfl-bg.jpg"
import Pedido from "../components/Pedido"
import SliderHome from "../components/SliderHome"
import Month from "../components/Month"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Menu />
    <Hero />
    <About />
    <Divider content={img1} />
    <Monte />
    <Divider content={img2} />
    <SliderHome />
    <Pedido />
    {/* <Month /> */}
  </Layout>
)

export default IndexPage
