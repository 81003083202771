import React from "react"
import { Parallax } from "react-parallax"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

import bg from "../../images/banner-ifood-bg.jpg"

const Pedido = () => {
  const { logoIfood, logoGoburguer } = useStaticQuery(
    graphql`
      query {
        logoIfood: file(relativePath: { eq: "logo-ifood.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoGoburguer: file(relativePath: { eq: "go-burguer-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <Parallax
      blur={0}
      bgImage={bg}
      bgImageAlt="the cat"
      strength={200}
      style={{ marginTop: "-3px" }}
    >
      <S.ComponentWrapper id="peca">
        <S.Container>
          <S.Title>Faça seu pedido online!</S.Title>
          <S.LogosWrapper>
            <div>
              <S.Botao
                href="http://pedir.to/goburguer"
                target="_blank"
                rel="noopener noreferrer"
              >
                Clique aqui e faça seu pedido!
              </S.Botao>
            </div>
          </S.LogosWrapper>
          <S.PlayDiv>
            <br />
            <p>Ou baixe o nosso aplicativo agora mesmo:</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a href="https://play.google.com/store/apps/details?id=com.br.goburguer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Disponível no Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png"
                />
              </a>
              <a href="https://apps.apple.com/br/app/go-burguer-delivery/id1576247374?itsct=apps_box_badge&amp;itscg=30200">
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br?size=250x83&amp;releaseDate=1626134400&h=7133ec4656fba1fd28cbfd5f3a6c5b58"
                  alt="Download on the App Store"
                  style={{
                    height: "52px",
                  }}
                />
              </a>
            </div>
          </S.PlayDiv>
        </S.Container>
      </S.ComponentWrapper>
    </Parallax>
  )
}

export default Pedido
