import styled from "styled-components"

import { Link } from "gatsby"

export const ComponentWrapper = styled.header`
  background: black;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  height: 67px;
`

export const Nav = styled.nav`
  max-width: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NavLinks = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
`

export const NavLinksItem = styled.li`
  padding: 25px 15px;

  @media (max-width: 768px) {
    padding: 25px 7px;
  }

  a {
    font-family: "Arvo";
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
`

export const NavLinksLink = styled(Link)`
  font-family: "Arvo";
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`
