import React from "react"

import * as S from "./styles"

const Menu = () => (
  <S.ComponentWrapper>
    <S.Nav>
      <S.NavLinks>
        <S.NavLinksItem>
          <S.NavLinksLink to="/">Home</S.NavLinksLink>
        </S.NavLinksItem>
        <S.NavLinksItem>
          <S.NavLinksLink to="/#monte">Como funciona</S.NavLinksLink>
        </S.NavLinksItem>
        <S.NavLinksItem>
          <a href="http://pedir.to/goburguer" target="_blank">
            Cardápio
          </a>
        </S.NavLinksItem>
        <S.NavLinksItem>
          <S.NavLinksLink to="/#peca">Faça seu pedido</S.NavLinksLink>
        </S.NavLinksItem>
      </S.NavLinks>
    </S.Nav>
  </S.ComponentWrapper>
)

export default Menu
