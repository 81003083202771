import styled from "styled-components"
import Img from "gatsby-image"

export const ComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  width: 90%;
  max-width: 1080px;
  padding: 60px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h2`
  color: white;
  font-family: Arvo;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 30px;
  text-align: center;
`

export const SubTitle = styled.h4`
  font-size: 20px;
  color: white;
  margin-top: 5px;
`

export const LogosWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 50%;
  align-items: center;

  div {
    flex: 1;
  }
`

export const Logo = styled(Img)`
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
`

export const PlayDiv = styled.div`
  max-width: 420px;
  margin: 20px 0;

  p {
    font-family: Arvo;
    text-align: center;
    color: white;
    font-size: 18px;
  }

  img {
    max-width: 200px;
    margin: 0 auto;
  }
`

export const Botao = styled.a`
  background: black;
  color: white;
  text-decoration: none;
  padding: 20px;
  display: block;
  max-width: 400px;
  margin: 0 auto;
  font-family: Arvo;
  text-transform: uppercase;
  text-align: center;
  border: solid;
  transition: 0.5s all;

  &:hover {
    box-shadow: 2px 2px 20px black;
  }
`
